import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { get, compact, last } from "lodash";
import PropTypes from "prop-types";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";

import Banner from "../../components/Banner";
import { ContentBlock, TextBlock } from "../../components/Blocks";
import Contents from "../../components/Contents";
import Pages from "../../components/Pages";
import { makeUrl, removePreloader, makeVirtualTourSlides } from "../../helpers";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { Share } from "../../widgets";
import MapComponent from "../../components/Map";
import { SHRINE_MAX_UPDATE, SHRINE } from "../../queries/queries.graphql";
import { PureCarousel } from "../../components/PureReactCarousel";
import EmptyData from "../../components/EmptyData";

export const query = graphql`
	query shrinesDataQuery($slug: String!) {
		hasura {
			...Shrine
		}
	}
`;

export default function ShrinesPage({ location, data }) {
	const [path] = useState(last(compact(get(location, "pathname", "").split("/"))));

	const [shrines, setShrines] = useState(get(data, "hasura.shrines[0]", {}));

	const maxUpdated = new Date(get(data, "hasura.shrines_aggregate.aggregate.max.updated_at", new Date()));

	const shrineOptions = {
		fetchPolicy: "no-cache",
		variables: {
			location: path,
		},
	};

	const { loading: maxUpdateLoading, data: maxUpdateData, error: maxUpdateError } = useQuery(SHRINE_MAX_UPDATE, shrineOptions);
	const [loadShrine, { loading: dataLoading, called, data: fetchData, error: dataError }] = useLazyQuery(SHRINE, shrineOptions);

	useEffect(() => {
		const currentMaxUpdated = new Date(get(maxUpdateData, "shrines_aggregate.aggregate.max.updated_at", new Date()));

		if (maxUpdateData && !maxUpdateError) {
			if (+currentMaxUpdated !== +maxUpdated) {
				loadShrine();
			} else {
				removePreloader();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load ShrineMaxUpdate", { maxUpdateError });
			removePreloader();
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (called && fetchData && !dataError) {
			setShrines(get(fetchData, "shrines[0]", {}));
		}

		removePreloader(!dataLoading || dataError);
	}, [dataLoading]);

	const { content_blocks, title_full, main_image, page_title_short, page_title_full, location_object } = shrines;

	const url = makeUrl.shrine(shrines);
	const imageSrc = get(main_image, "src", "");

	const bannerSlides = [
		{
			title: get(shrines, "page_title_full", ""),
			publicURL: get(shrines, "main_image.src"),
			mainImageMobile: get(shrines, "main_image_mobile.src", ""),
		},
	];
	const virtualTour = makeVirtualTourSlides(get(shrines, "shrines_virtual_tours", []));

	return shrines ? (
		<Pages entity={shrines} url={url} >
			<ContentBlock key={"banner"}>
				<Banner slides={bannerSlides} />
			</ContentBlock>
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs currentLabel={`Святыни / ${page_title_short || title_full}`} />
				</div>
			</ContentBlock>
			<ContentBlock key={"media-content"} mount={content_blocks && !!content_blocks.length}>
				<div className={"container"}>
					<Contents items={content_blocks} />
				</div>
			</ContentBlock>
			<ContentBlock key={"virtual_tours"} mount={virtualTour && !!virtualTour.length}>
				<div className={"container"} >
					<TextBlock title={"Виртуальные экскурсии"} id={"Virtual-Tours"}>
						<PureCarousel
							type={"SlideLinks"}
							items={virtualTour}
							slideRatio={{
								naturalSlideWidth: 2,
								naturalSlideHeight: 1,
							}}
							slideRatioMobile={{
								naturalSlideWidth: 1,
								naturalSlideHeight: 0.8,
							}}
							params={{
								slidesPerView: 2,
								breakpoints: [
									{
										width: 0,
										slidesPerView: 1,
									},
									{
										width: 576,
										slidesPerView: 2,
									},
									{
										width: 768,
										slidesPerView: 2,
									},
									{
										width: 1136,
										slidesPerView: 2,
									},
								],
							}}
						/>
					</TextBlock>
				</div>
			</ContentBlock>
			<ContentBlock mount={shrines && location_object} key={"map"}>
				<div className={"container"}>
					<div className={"row"}>
						<div className={"col-12"}>
							<TextBlock title={"Расположение"} />
						</div>
					</div>
				</div>
				<MapComponent items={[shrines]} showPolygon />
			</ContentBlock>
			<div className={"container"}>
				<Share
					url={url}
					pageTitleShort={page_title_short}
					pageTitleFull={page_title_full}
					imageSrc={imageSrc}
				/>
			</div>
		</Pages>
	) : (
		<EmptyData />
	);
}

ShrinesPage.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
	pageContext: PropTypes.object,
};

ShrinesPage.defaultProps = {
	data: {},
	location: {
		pathname: "",
	},
	pageContext: {},
};
